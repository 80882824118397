<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col lg="2" sm="6">
              <b-button variant="relief-success" v-b-modal.modal-store>
               Adicionar
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
            <b-col>
              <!-- <b-form-input
                placeholder="Pesquisar"
                v-model="search"
                debounce="800"
                size="sm"
                type="search"
              /> -->
            </b-col>
          </b-row>
          <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
          <b-table-simple
              striped
              stacked="md"
              class="mt-2 responsive text-center"
              id="tableUrlVideos"
              :item="fetchUrlVideos"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Video</b-th>
                  <b-th>Título</b-th>
                  <b-th>Rota</b-th>
                  <b-th>Data de registro</b-th>
                  <b-th>Ação</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in urlVideos">
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                    >
                      <a :href="tr.url" target="_blank">
                        <feather-icon icon="EyeIcon" />
                      </a>
                    </b-button>
                  </b-td>
                  <b-td>
                    {{tr.title}}
                  </b-td>
                  <b-td>
                    {{tr.routes_app}}
                  </b-td>
                  <b-td>
                    {{tr.created_at | dateFormat }}
                  </b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-warning"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-update
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-delete
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableUrlVideos"
              class="mt-1"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <!-- Modal de delete -->
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete"
      centered
      title="Deletar"
    >
      <b-card-text>
        Você está preste a deletar este vídeo do sistema:
        <hr>
        <p> <b>Titulo: </b>{{urlVideosModel ? urlVideosModel.title : ''}} </p>
        <p> <b>Vídeo: </b> <a :href="urlVideosModel ? urlVideosModel.url : ''" target="_blank"> Ver</a></p>
        <p> <b>Rota do App: </b>{{urlVideosModel ? urlVideosModel.routes_app : ''}}</p>
        <hr>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir esta mensagem
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == null"
            @click="deleteUrlVideo(urlVideosModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Modal de store -->
    <b-modal
      id="modal-store"
      modal-class="modal-success"
      ref="modal-store"
      centered
      title="Cadastrar"
    >
      <b-card-text>
        <label> URL </label>
        <b-form-input
          placeholder="url"
          v-model="dataUrlVideo.url"
          id="name"
        />
        <label> Título </label>
        <b-form-input
          placeholder="Título"
          v-model="dataUrlVideo.title"
          id="title"
        />
        <label> Rota do Aplicativo </label>
        <!-- <b-form-input
          placeholder="Rotas"
          v-model="dataUrlVideo.routes_app"
          id="routes_app"
        /> -->
        <b-form-select
          v-model="dataUrlVideo.routes_app"
          :options="optionsRouters"
        />
      </b-card-text>
      <template #modal-footer>
         <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-1"
          @click="storeUrlVideo"
        >
          Cadastrar
        </b-button>
      </template>
    </b-modal>

    <!-- Modal de editar -->
    <b-modal
      id="modal-update"
      ok-variant="warning"
      modal-class="modal-warning"
      ref="modal-update"
      centered
      title="Edição"
    >
      <b-card-text>
        Você está preste a editar este vídeo do sistema:
        <hr>
      </b-card-text>
      <div v-if="urlVideosModel">
        <label class="mt-1"> Novo Título </label>
        <b-form-input
          placeholder="Novo Título"
          id="title"
         :value="updateUrlVideos.title = urlVideosModel.title "
         @change="newTitleVideo"
        >
          {{urlVideosModel.title}}
        </b-form-input>
        <label class="mt-1"> Nova Url </label>
        <b-form-input
          placeholder="Nova Url"
          id="url"
          :value="updateUrlVideos.url = urlVideosModel.url "
          @change="newUrlVideo"
        >
          {{urlVideosModel.url}}
        </b-form-input>
        <label class="mt-1"> Nova Rota </label>
        <!-- <b-form-input
          placeholder="Nova Rota"
          id="title"
          :value="updateUrlVideos.routes_app = urlVideosModel.routes_app "
          @change="newRouteVideo"
        >
          {{urlVideosModel.routes_app}}
        </b-form-input> -->

        <b-form-select
          :value="updateUrlVideos.routes_app = urlVideosModel.routes_app "
          :options="optionsRouters"
          @change="newRouteVideo"
        >
           {{urlVideosModel.routes_app}}
        </b-form-select>
      </div>
      <div v-else>
        Vídeo não encontrado
      </div>
      <hr>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-warning"
            block
            :value="updateUrlVideos.id = urlVideosModel.id"
            @click="updateUrlVideo"
          >
            Editar
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      DeleteStatus: null,

      urlVideosModel: null,

      id_delete: null,

      perPageAmount: 10,
      currentPageAmount: 1,
      totalRowsAmount: null,

      perPageFrequency: 10,
      currentPageFrequency: 1,
      totalRowsFrequency: null,


      dataUrlVideo: {
        url: null,
        title: null,
        routes_app: null
      },

      updateUrlVideos: {
        id: null,
        url: null,
        title: null,
        routes_app: null
      },

      selected: null,
      optionsRouters: [
        { value: null, text: 'Por favor selecione uma opção' },
        { value: 'Home', text: 'Tela Inicial' },
        { value: 'Prescription', text: 'Receita Médica' },
        { value: 'Pharmacy', text: 'Farmacia' },
        { value: 'Schedule', text: 'Agenda' },
        { value: 'EditProfile', text: 'Editar Perfil' },
        { value: 'AddElderly', text: 'Adicionar Idosos' },
        { value: 'ElderlyList', text: 'Lista de Idosos' },
        { value: 'SosForm', text: 'Formulário SOS' },

      ],
    };
  },
  computed: {
    ...mapState("urlVideos", ["urlVideos"]),
  },
  methods: {
     ...mapActions("urlVideos", ["store", "update"]),
    storeUrlVideo() {
      this.store({
        ...this.dataUrlVideo,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchUrlVideos()
        this.$refs['modal-store'].hide()
      });
    },
    updateUrlVideo() {
      this.update({
        ...this.updateUrlVideos,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
      })
      .finally(() =>{
        this.fetchUrlVideos()
        this.$refs['modal-update'].hide()
      });
    },
    deleteUrlVideo(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("urlVideos/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchUrlVideos()
        this.$refs['modal-delete'].hide()
      });
    },
    fetchUrlVideos(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("urlVideos/fetchUrlVideos", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(id){
      this.urlVideosModel = this.$store.getters['urlVideos/getUrlVideoById'](id)
    },
    handlePageChange(value) {
      this.fetchUrlVideos(true, value);
    },
    newDeleteStatus(value){
      this.DeleteStatus = value;
    },

    newTitleVideo(value){
      this.updateUrlVideos.title = value;
    },

    newUrlVideo(value){
      this.updateUrlVideos.url = value;
    },

    newRouteVideo(value){
      this.updateUrlVideos.routes_app = value;
    },

  },
  created() {
    this.fetchUrlVideos();
  },
  watch: {
     search(val) {
      this.currentPage = 1;
      this.fetchUrlVideos(true, 1);
    },

  },
};
</script>
